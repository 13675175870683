import { useCookie } from '#imports';
export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    const company = useCookie('company');

    if (typeof from.query?.c === 'string') {
      company.value = from.query?.c;
    }
  }
});
