export default defineAppConfig({
  winx: {
    titleBase: 'titleBase',
  },
  tairo: {
    sidebar: {
      circularMenu: {
        enabled: false,
      },
    },
    panels: [
      {
        name: 'language',
        position: 'right',
        component: 'PanelLanguage',
      },
    ],
  },
});
