import { default as _91form_93r7iTqIM8XfMeta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedeY5PbOkIn6Meta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishGMq743L5GlMeta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as startQ6JmHUqXi1Meta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_930ZtIIHZmyJMeta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexRLnEf7Ohr9Meta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginLuHZyy49syMeta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectMZngpi9kVnMeta } from "/codebuild/output/src1734911482/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_930ZtIIHZmyJMeta?.name ?? "research",
    path: _91_91research_93_930ZtIIHZmyJMeta?.path ?? "/:research?",
    meta: _91_91research_93_930ZtIIHZmyJMeta || {},
    alias: _91_91research_93_930ZtIIHZmyJMeta?.alias || [],
    redirect: _91_91research_93_930ZtIIHZmyJMeta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93r7iTqIM8XfMeta?.name ?? "research-form",
    path: _91form_93r7iTqIM8XfMeta?.path ?? ":form()",
    meta: _91form_93r7iTqIM8XfMeta || {},
    alias: _91form_93r7iTqIM8XfMeta?.alias || [],
    redirect: _91form_93r7iTqIM8XfMeta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedeY5PbOkIn6Meta?.name ?? "research-denied",
    path: deniedeY5PbOkIn6Meta?.path ?? "denied",
    meta: deniedeY5PbOkIn6Meta || {},
    alias: deniedeY5PbOkIn6Meta?.alias || [],
    redirect: deniedeY5PbOkIn6Meta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishGMq743L5GlMeta?.name ?? "research-finish",
    path: finishGMq743L5GlMeta?.path ?? "finish",
    meta: finishGMq743L5GlMeta || {},
    alias: finishGMq743L5GlMeta?.alias || [],
    redirect: finishGMq743L5GlMeta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: startQ6JmHUqXi1Meta?.name ?? "research-start",
    path: startQ6JmHUqXi1Meta?.path ?? "start",
    meta: startQ6JmHUqXi1Meta || {},
    alias: startQ6JmHUqXi1Meta?.alias || [],
    redirect: startQ6JmHUqXi1Meta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexRLnEf7Ohr9Meta?.name ?? "index",
    path: indexRLnEf7Ohr9Meta?.path ?? "/",
    meta: indexRLnEf7Ohr9Meta || {},
    alias: indexRLnEf7Ohr9Meta?.alias || [],
    redirect: indexRLnEf7Ohr9Meta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginLuHZyy49syMeta?.name ?? "login",
    path: loginLuHZyy49syMeta?.path ?? "/login",
    meta: loginLuHZyy49syMeta || {},
    alias: loginLuHZyy49syMeta?.alias || [],
    redirect: loginLuHZyy49syMeta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectMZngpi9kVnMeta?.name ?? "redirect",
    path: redirectMZngpi9kVnMeta?.path ?? "/redirect",
    meta: redirectMZngpi9kVnMeta || {},
    alias: redirectMZngpi9kVnMeta?.alias || [],
    redirect: redirectMZngpi9kVnMeta?.redirect,
    component: () => import("/codebuild/output/src1734911482/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]